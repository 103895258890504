<p-dropdown
	styleClass="sl-v1-dropdown"
	panelStyleClass="sl-v1-dropdown-panel"
	[scrollHeight]="configSignal$().scrollHeight"
	[optionLabel]="configSignal$().optionLabel"
	optionDisabled="disabled"
	[placeholder]="configSignal$().placeholder"
	[appendTo]="configSignal$().appendTo"
	[options]="optionsSignal$()"
	[filter]="configSignal$().showFilter"
	[filterBy]="configSignal$().optionLabel"
	[formControl]="searchControl"
	(onChange)="onChange($event)">
	<ng-template let-item pTemplate="item">
		@if (configSignal$()?.itemTemplate) {
			<ng-container [ngTemplateOutlet]="configSignal$().itemTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
		} @else {
			<div [title]="item[configSignal$().optionLabel]">{{ item[configSignal$().optionLabel] }}</div>
		}
	</ng-template>
	<ng-template let-item pTemplate="selectedItem">
		@if (configSignal$()?.itemTemplate) {
			<ng-container [ngTemplateOutlet]="configSignal$()?.itemTemplate" [ngTemplateOutletContext]="{ item }"></ng-container>
		} @else {
			<div [title]="item?.[configSignal$()?.optionLabel]">{{ item?.[configSignal$()?.optionLabel] }}</div>
		}
	</ng-template>
	<ng-template pTemplate="footer">
		@if (loadingConfigSignal$()) {
			<div class="dropdown-footer sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-start">
				<div [class.hidden]="isLoadingItems" class="sl-typography-link-action load-next" (click)="onLoadMoreItems()">
					Load next {{ loadingConfigSignal$().nextPageSize }} out of {{ loadingConfigSignal$().total - optionsSignal$().length }}...
				</div>
				<div [class.hidden]="!isLoadingItems" class="sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-center sl-flexbox-flex-dynamic">
					<mat-spinner [diameter]="24"></mat-spinner>
					<span class="sl-typography-body">Loading items...</span>
				</div>
			</div>
		}
	</ng-template>
</p-dropdown>
