import { TemplateRef } from '@angular/core';

export interface SelectItem extends Record<string, any> {
	name: string;
}

export interface SelectConfig {
	showFilter?: boolean;
	optionLabel?: string;
	scrollHeight?: string;
	appendTo?: string;
	placeholder?: string;
	itemTemplate?: TemplateRef<any>;
}

export interface SelectLoadingConfig {
	nextPageSize: number;
	total: number;
}

export const DEFAULT_SELECT_CONFIG = {
	showFilter: true,
	optionLabel: 'name',
	scrollHeight: '208px',
	appendTo: 'body',
	placeholder: 'select an option'
} as SelectConfig;
