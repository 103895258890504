import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnChanges, output, SimpleChanges, viewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Dropdown, DropdownModule } from 'primeng/dropdown';

import { DEFAULT_SELECT_CONFIG, SelectConfig, SelectItem, SelectLoadingConfig } from '../../models/select.model';

@Component({
	selector: 'sl-select',
	templateUrl: './select.component.html',
	styleUrl: './select.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		NgTemplateOutlet,
		DropdownModule,
		FormsModule,
		MatProgressSpinnerModule,
		ReactiveFormsModule
	],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: SelectComponent,
		multi: true
	}],
})
export class SelectComponent implements OnChanges, ControlValueAccessor {

	readonly optionsSignal$ = input<SelectItem[]>(null, { alias: 'options' });
	readonly placeholderSignal$ = input<string>(null, { alias: 'placeholder' });
	readonly configSignal$ = input(DEFAULT_SELECT_CONFIG, {
		alias: 'config',
		transform: (item: SelectConfig): SelectConfig => ({ ...DEFAULT_SELECT_CONFIG, ...(item || {}) })
	});
	readonly loadingConfigSignal$ = input<SelectLoadingConfig>(null, { alias: 'loadingConfig' });

	readonly selectionChanged = output<SelectItem>();
	readonly loadMoreItems = output<void>();

	readonly select = viewChild.required<Dropdown>(Dropdown);

	readonly searchControl = new FormControl<SelectItem>(null);

	isLoadingItems = true;
	onChangeFunction: (fn: any) => void;
	onTouchedFunction: () => void;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.optionsSignal$) {
			if (Array.isArray(changes.optionsSignal$.currentValue) && changes.optionsSignal$.currentValue.length > 0) {
				this.isLoadingItems = false;
			}
		}
	}

	onChange(event: { originalEvent: PointerEvent; value: SelectItem }): void {
		this.selectionChanged.emit(event.value);
		if (this.onChangeFunction) {
			this.onChangeFunction(event.value);
		}
	}

	onLoadMoreItems(): void {
		this.loadMoreItems.emit();
		this.isLoadingItems = true;
	}

	writeValue(item: SelectItem): void {
		this.selectionChanged.emit(item);
		this.setItemInList(item);
	}

	setItemInList(item: SelectItem): void {
		const itemInList = this.optionsSignal$()?.find(x => x.name === item?.name);
		if (itemInList) {
			this.searchControl.setValue(itemInList);
		} else {
			this.searchControl.setValue(item);
		}
	}

	registerOnChange(fn: () => void): void {
		this.onChangeFunction = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouchedFunction = fn;
		this.select().registerOnTouched(this.onTouchedFunction);
	}
}
